:root {
  --rating-btn-border-color: gray;
  --rating-btn-border-radius: 3px;
}

.rating-btn {
  background-color: transparent;
  border-top: 1px solid var(--rating-btn-border-color);
  border-bottom: 1px solid var(--rating-btn-border-color);
  border-right: 1px solid var(--rating-btn-border-color);
  border-left: 0px;
  color: black;
}

@media (max-width: 576px) {
  .rating-btn {
    padding: 10px;
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .rating-btn {
    flex-grow: 1;
    max-width: 50px;
  }
}

.rating-btn:hover {
  background-color: lightblue;
}

.rating-btn-start {
  border-top-left-radius: var(--rating-btn-border-radius);
  border-bottom-left-radius: var(--rating-btn-border-radius);
  border-left: 1px solid var(--rating-btn-border-color);
}

.rating-btn-end {
  border-top-right-radius: var(--rating-btn-border-radius);
  border-bottom-right-radius: var(--rating-btn-border-radius);
}

.rating-btn-selected {
  background-color: lightblue;
}